import React, { useEffect, useCallback } from "react";
import { CloseIcon } from "../../assets/";

const Modal = ({ visible, onClose, children, style }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (visible)
    return (
      <>
        <div id="modal" className="modal--backdrop" onClick={() => onClose()} />
        <div className="modal--container" style={style}>
          <div className="modal--container--close">
            <div
              className="modal--container--close--icon"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </div>
          </div>
          {children}
        </div>
      </>
    );
};

export default Modal;
