import React from "react";

function CorssIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M6.345 16.558a.903.903 0 010-1.277l8.724-8.723a.903.903 0 011.277 1.277l-8.724 8.723a.903.903 0 01-1.277 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M5.992 16.912a1.403 1.403 0 010-1.984l8.723-8.724A1.403 1.403 0 0116.7 8.188l-8.723 8.724a1.403 1.403 0 01-1.984 0zm.707-1.277a.403.403 0 10.57.57l8.723-8.724a.403.403 0 10-.57-.57L6.7 15.636z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M6.345 6.345a.903.903 0 011.277 0l8.724 8.724a.903.903 0 01-1.277 1.277L6.345 7.622a.903.903 0 010-1.277z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#BABABA"
        fillRule="evenodd"
        d="M5.992 5.992a1.403 1.403 0 011.984 0l8.723 8.723a1.403 1.403 0 11-1.984 1.984L5.992 7.976a1.403 1.403 0 010-1.984zm1.277.707a.403.403 0 10-.57.57l8.723 8.723a.403.403 0 10.57-.57L7.269 6.7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CorssIcon;
