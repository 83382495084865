import React from "react";
import "./style.css";
import TableBodyOption from "./TableBodyOption";
import TableHeader from "./TableHeader";
import { useTranslation } from "react-i18next";

const Table = ({ tableData }) => {
  const { t } = useTranslation();
  const header = [
    t("table.title2"),
    t("table.title3"),
    t("table.title4"),
    "Claim",
  ];
  return (
    <div className="table--container-outer aic anim">
      <div className="table--container aic anim">
        <TableHeader header={header} />
        {tableData.map((_, i) => {
          if (_.date !== "0") {
            return <TableBodyOption key={i} row={_} />;
          }
        })}
      </div>
    </div>
  );
};

export default Table;
