import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import MemeberDetail from "../components/MemeberDetail";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Disclaimer from "../components/Disclaimer";

const MainPage = () => {
  const { t } = useTranslation();
  const currentLanguageCode = localStorage.getItem("i18nextLng") || "es";

  const form = useRef();
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    wallet: "",
    country: "",
    message: "",
  });
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs
      .sendForm(
        "service_ymoouzq",
        "template_cssca0e",
        form.current,
        "LLOWO7d6jtDerzoPF"
      )
      .then(
        (result) => {
          setFormData({
            name: "",
            email: "",
            phone: "",
            wallet: "",
            country: "",
          });
          alert(result.text);
        },
        (error) => {
          alert(error.text);
        }
      );
  };
  const tokens = [
    {
      img: "./images/t1.png",
      desc: "option1",
    },
    { img: "./images/t2.png", desc: "option2" },
    {
      img: "./images/t3.png",
      desc: "option3",
    },
    {
      img: "./images/t4.png",
      desc: "option4",
    },
    { img: "./images/t5.png", desc: "option5" },
    { img: "./images/t2.png", desc: "option6" },
    { img: "./images/t6.png", desc: "option7" },
  ];
  const teamGroup1 = [
    {
      img: "./images/team1.png",
      name: "Jose del Corral",
      link: "https://www.linkedin.com/in/joseestebandelcorralsanchez/",
      designation: "Co-Founder & CEO",
    },
    {
      img: "./images/team2.png",
      name: "Dr. Nabil Hajji",
      link: "https://scholar.google.com/citations?user=3EAaRykAAAAJ&hl=en&oi=ao",
      designation: "Co-Founder",
    },
    {
      img: "./images/team3.png",
      name: "Miguel Vilaplana",
      link: "https://www.linkedin.com/in/miguel-vilaplana-serrano-a5473949/",
      designation: "Co-Founder & Chairman",
    },
    {
      img: "./images/team4.png",
      name: "Tony Solanes",
      link: "https://www.linkedin.com/in/tonysm/",
      designation: "Co-Founder & CBO",
    },
    {
      img: "./images/team5.png",
      name: "Frank Rodríguez",
      link: "https://www.linkedin.com/in/flinana/",
      designation: "Co-Founder & CCO ",
    },
    {
      img: "./images/team6.png",
      name: "Manuel Ortigueira",
      link: "https://www.linkedin.com/in/manuel-ortigueira-sanchez-b3a50929/",
      designation: "Co-Founder",
    },
    {
      img: "./images/team7.png",
      name: "María José Salao",
      link: "https://www.linkedin.com/in/mar%C3%ADa-jos%C3%A9-salado-monsalves-5348a0a8/",
      designation: "Co-Founder & Product Manager",
    },
    {
      img: "./images/team8.png",
      name: "Jesús Ortigueira",
      link: "https://www.linkedin.com/in/jesus-ortigueira-s%C3%A1nchez-b47a92116/",
      designation: "Co-Founder & Strategic Advisor ",
    },
  ];
  const teamGroup2 = [
    {
      img: "./images/team9.png",
      name: "Lourdes Hontecillas Prieto",
      link: "https://www.researchgate.net/scientific-contributions/Lourdes-Hontecillas-Prieto-2069134151",
      designation: "Lab Manager",
    },
    {
      img: "./images/team10.png",
      name: "Lucía Martín Banderas",
      link: "https://www.researchgate.net/profile/Lucia-Martin-Banderas",
      designation: "Pharmaceutical Technology",
    },
    {
      img: "./images/team11.png",
      name: "Daniel J García-Domínguez",
      link: "https://www.researchgate.net/profile/Daniel-Garcia-Dominguez",
      designation: "Molecular Biology",
    },
    {
      img: "./images/team12.png",
      name: "Alberto Perez ",
      link: "https://www.linkedin.com/in/alberto-p%C3%A9rez-monta%C3%B1a-93777b13/",
      designation: "Secretary & Legal Advisor",
    },
    {
      img: "./images/team13.png",
      name: "Antonio Moron",
      link: "https://www.linkedin.com/in/antonio-mor%C3%B3n-orta-a971395a/",
      designation: "Legal Department",
    },
    {
      img: "./images/team14.png",
      name: "José Manuel Talero",
      link: "https://www.linkedin.com/in/jos%C3%A9-manuel-talero-0130744/",
      designation: "México Director",
    },
    {
      img: "./images/team15.png",
      name: "Hernan Reyes ",
      link: "https://www.linkedin.com/in/augusto-hernan-reyes-7bba17206/",
      designation: "Australia Director",
    },
    {
      img: "./images/team16.png",
      name: "Vincent De Wulf ",
      link: "https://www.linkedin.com/in/vincent-dewulf-79046a6/",
      designation: "Benelux Director",
    },
  ];
  const teamGroup3 = [
    {
      img: "./images/team17.png",
      name: "Ricardo Benítez",
      link: "https://www.linkedin.com/in/ricardo-ben%C3%ADtez-bajandas/",
      designation: "USA Director",
    },
    {
      img: "./images/team18.png",
      name: "Kade Anderson ",
      link: "https://www.linkedin.com/in/kade-anderson-58ba2222a/",
      designation: "Thailand Director",
    },
    {
      img: "./images/team19.png",
      name: "Yvan Stern-Plaza ",
      link: "https://www.linkedin.com/in/ysp/",
      designation: "Canada Director",
    },
    {
      img: "./images/team20.png",
      name: "Younes Malki ",
      link: "https://www.linkedin.com/in/younes-malki-m-eng-pmp-72a16325/",
      designation: "North Africa Director",
    },
    {
      img: "./images/team21.png",
      name: "Deon Du Toit ",
      link: "https://www.linkedin.com/in/deon-du-toit-a4006770/",
      designation: "South Africa Director",
    },
    {
      img: "./images/team22.png",
      name: "Ailene Thiel",
      link: "https://www.linkedin.com/in/ailene-thiel-828ab23/",
      designation: "Clinical Trial Advisor ",
    },
    {
      img: "./images/team23.png",
      name: "Belinda Hartmann",
      link: "https://www.linkedin.com/in/belindahartmannphd/",
      designation: "PI Advisor ",
    },
    {
      img: "./images/team24.png",
      name: "Enrique Domínguez",
      link: "https://www.linkedin.com/in/enrique-dominguez/",
      designation: "IA Advisor ",
    },
  ];
  const teamGroup4 = [
    {
      img: "./images/team25.png",
      name: "Carlos Callejo ",
      link: "https://www.linkedin.com/in/carlos-callejo/",
      designation: "Developer TGE Advisor",
    },
    {
      img: "./images/team26.png",
      name: "Eric Sánchez ",
      link: "https://www.linkedin.com/in/ericsanchezgalvez/",
      designation: "Strategic TGE Advisor",
    },
    {
      img: "./images/team27.png",
      name: "Ismael Santiago ",
      link: "https://www.linkedin.com/in/phd-ismael-santiago-48918724/",
      designation: "Financial TGE Advisor",
    },
    {
      img: "./images/team28.png",
      name: "Roberto Espinosa ",
      link: "https://www.linkedin.com/in/roberespinosamarketingvalencia/",
      designation: "Marketing TGE Advisor",
    },
    {
      img: "./images/team29.png",
      name: "Ricardo Torres ",
      link: "https://www.linkedin.com/in/rtorrescode/",
      designation: "Legal TGE Advisor",
    },
    {
      img: "./images/team30.png",
      name: "David Rodríguez ",
      link: "https://www.linkedin.com/in/david-rodr%C3%ADguez-ure%C3%B1a-a306588/",
      designation: "Marketing ICO Advisor",
    },
    {
      img: "./images/team31.png",
      name: "Rubén Mancera Arcos ",
      link: "https://www.linkedin.com/in/rubenmanceraarcos/",
      designation: "Community & NFT Advisor",
    },
    {
      img: "./images/team32.png",
      name: "Rayco Tarrida",
      link: "https://www.linkedin.com/in/raycotarrida/",
      designation: "Blockchain TGE Advisor",
    },
  ];
  var teamSetting = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var newsSetting = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const newsList = [
    {
      img: "./images/news1.png",
      link: "https://www.larazon.es/economia/20220629/ddknipxg35hi5c4u3s5w65alea.html",
    },
    {
      img: "./images/news2.png",
      link: "https://okdiario.com/salud/inteligencia-artificial-clave-optimizar-decisiones-clinicas-pacientes-oncologicos-9318905",
    },
    {
      img: "./images/news3.png",
      link: "https://popnews247.com/keyzell-awarded-for-treating-cancer-with-biotechnology-artificial-intelligence/",
    },
    {
      img: "./images/news4.png",
      link: "https://www.elindependiente.com/futuro/2022/06/28/keyzell-galardonada-por-su-desarrollo-de-inteligencia-artificial-para-tratamientos-contra-el-cancer/",
    },
    {
      img: "./images/news5.png",
      link: "https://es.cointelegraph.com/news/biotech-startup-uses-crypto-assets-to-fight-cancer",
    },
    {
      img: "./images/news6.png",
      link: "https://www.territoriobitcoin.com/keyzell-apuesta-por-los-criptoactivos-para-desarrollar-inteligencia-artificial-contra-el-cancer/",
    },
    {
      img: "./images/news7.png",
      link: "https://www.farmabiotec.com/noticias/la-biotecnologica-keyzell-da-una-oportunidad-a-los-criptoactivos-para-desarrollar-int-YddXY",
    },
    {
      img: "./images/news8.png",
      link: "https://medicinaresponsable.com/new/tratamientos-personalizados-contra-el-cancer-de-pulmon-y-de-mama-gracias-a-la-inteligencia-artificial-1652946614952",
    },
    {
      img: "./images/news9.png",
      link: "https://elpais.com/babelia/2022-05-13/dos-tipos-de-ayudas-que-llegan-desde-el-mundo-digital.html",
    },
    {
      img: "./images/news10.png",
      link: "https://www.lavanguardia.com/economia/20220720/8419525/ax-partners-lanza-sitges-proyecto-60-millones.html",
    },
    {
      img: "./images/news11.png",
      link: "https://markets.businessinsider.com/news/stocks/keyzell-start-up-plans-to-reach-5-million-euros-in-private-investment-and-become-the-new-biotech-unicorn-1031287531",
    },
    {
      img: "./images/news12.png",
      link: "https://cronicaglobal.elespanol.com/vida/medicina-precision-clave-en-lucha-contra-cancer_629078_102.html",
    },
    {
      img: "./images/news13.png",
      link: "https://www.consalud.es/consalud-tv/keyzell-tratamientos-cancer-bloqueo-neuropeptidos_111351_102.html",
    },
    {
      img: "./images/news14.png",
      link: "https://www.abc.es/salud/enfermedades/abci-matar-hambre-glioblastoma-cancer-cerebral-mas-mortal-202203130156_noticia.html",
    },
    {
      img: "./images/news15.jpeg",
      link: "https://www.htworld.co.uk/featured/health-tech-world-top-top-50-innovators-of-2022/",
    },
    {
      img: "./images/news16.png",
      link: "https://infonegocios.madrid/nota-principal/keyzell-lanza-un-token-que-ayuda-a-tratar-el-cancer-con-ia-una-solucion-para-medicos-y-pacientes",
    },
    {
      img: "./images/news17.png",
      link: "https://startupsmagazine.co.uk/article-keyzell-launches-k2z-first-utility-token-against-cancer",
    },
    {
      img: "./images/news18.png",
      link: "https://www.territoriobitcoin.com/keyzell-continua-avanzando-en-el-desarrollo-de-su-herramienta-de-inteligencia-artificial/",
    },
    {
      img: "./images/news19.png",
      link: "https://territorioblockchain.com/keyzell-lanza-el-primer-token-de-utilidad/",
    },
  ];
  const collaborationList = [
    { img: "./images/collab1.png", slug: "" },
    { img: "./images/collab2.png", slug: "" },
    { img: "./images/collab3.png", slug: "" },
    { img: "./images/collab4.png", slug: "" },
    { img: "./images/collab5.png", slug: "" },
    { img: "./images/collab6.png", slug: "" },
  ];

  return (
    <div className="home-page flex flex-col">
      <Disclaimer />
      <Header />
      <div className="hero-sec flex">
        <div
          className="hm-bg flex"
          style={{ backgroundImage: `url("./images/home-bg1.svg")` }}
        >
          <div className="wrap wrapWidth flex items-center justify-center">
            <div className="left flex">
              <div className="left-block flex flex-col items-center justify-center w-full">
                <div className="slogan flex flex-col items-center">
                  <div className="flex items-center" style={{ gap: "16px" }}>
                    <span className="s-1 flex"> {t("hero.ia")} </span>
                    <span className="s-2 flex">{t("hero.title")}</span>
                  </div>

                  <span className="s-2 flex">{t("hero.title2")}</span>
                  <span className="s-2 flex">{t("hero.title3")}</span>
                </div>
                <div className="homebg-blk flex w-1/2">
                  <img
                    alt="prop tag"
                    src="./images/home-bg.png"
                    className="home-bg"
                  />
                </div>
                <div className="action flex w-full mt-6 flex-col">
                  <span className="s-2 flex">{t("hero.desc")}</span>

                  <Link
                    to="/compra"
                    className="btn button"
                    style={{
                      padding: "15px 40px",
                      textTransform: "uppercase",
                      fontSize: "18px",
                    }}
                  >
                    {t("extra.comprar")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="right flex items-center justify-end">
              {/* <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                <div className="tag pb-8">{t("phase.title1")}: 200,000 K2Z</div>
                <div className="tag pb-8">{t("phase.precio")}: 0.010 €</div>
                <div className="tag pb-8">{t("phase.title2")}: 30,000€</div>
                <div className="tag pb-8">{t("phase.title3")}: 300,000€</div>
                <div className="tag pb-8">
                  {t("phase.title4")}:{t("phase.period2")}
                </div>
                <div className="tag pb-8">
                  {t("phase.title5")}:12 {t("phase.period")}
                </div>
              </div> */}
              <form
                ref={form}
                className="right-block flex flex-col w-[70%]"
                onSubmit={handleSubmit}
              >
                <div
                  className="tag pb-1"
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                  }}
                >
                  {t("extra.question1")}
                </div>
                <div
                  className="tag pb-2"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {t("extra.sub1")}
                </div>
                <input
                  onChange={handleChange}
                  type="text"
                  className="txt mb-4"
                  placeholder={t("extra.nombre")}
                  name="name"
                  value={formData.name}
                  required
                />
                {/* <input
                  onChange={handleChange}
                  type="phone"
                  className="txt mb-4"
                  placeholder={t("extra.tel")}
                  name="phone"
                  value={formData.phone}
                  required
                /> */}
                <input
                  onChange={handleChange}
                  type="email"
                  className="txt mb-4"
                  placeholder="E-MAIL"
                  name="email"
                  value={formData.email}
                  required
                />
                {/* <input
                  onChange={handleChange}
                  type="text"
                  className="txt mb-4"
                  placeholder="BSC WALLET"
                  name="wallet"
                  value={formData.wallet}
                  required
                /> */}
                <input
                  onChange={handleChange}
                  type="text"
                  className="txt mb-4"
                  placeholder={t("extra.pais")}
                  name="country"
                  value={formData.country}
                  required
                />
                <textarea
                  onChange={handleChange}
                  // type="text"
                  className="txt mb-4"
                  placeholder={t("extra.mensaje")}
                  name="message"
                  value={formData.message}
                  style={{ lineHeight: "1rem" }}
                  required
                />
                <div
                  className="action flex justify-end w-full mt-6"
                  style={{ display: "flex " }}
                >
                  <button type="submit" className="btn button">
                    {t("extra.enviar")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="token-sec flex">
        <div className="wrap wrapWidth flex items-center flex-col">
          <div className="flex flex-col items-center justify-center pb-6">
            <div className="page-tag">{t("token.title")}</div>
            <br />
            <br />
            <div className="page-desc">{t("token.desc")}</div>
          </div>
          <div className="flex flex-col">
            <div className="tokens-wrap">
              {tokens.map((token, index) => {
                return (
                  <div className="token-card flex flex-col" key={index}>
                    <img alt="prop tag" src={token.img} className="token-img" />
                    <div className="token-desc">{t(`token.${token.desc}`)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="token-description flex">
        <div className="wrap wrapWidth flex flex-col justify-between">
          <div
            className="token-advantages flex flex-col"
            style={{ backgroundImage: `url("./images/home-bg1.svg")` }}
          >
            <div className="flex flex-col items-center justify-center pb-6">
              <div className="page-tag">{t("ventajas.title")}</div>
              <br />
              <br />
              <br />
              <div className="page-desc">{t("ventajas.desc")}</div>
            </div>
            <div className="road-map-block flex flex-col">
              <div className="road-map flex mt-12 w-[60%]">
                <div className="line w-full flex"></div>
                <div className="my-map w-full flex items-center justify-between flex-col">
                  <div className="flex items-center justify-between w-full">
                    <div className="numb">1</div>
                    <div className="numb">2</div>
                    <div className="numb">3</div>
                  </div>
                  <div className="flex items-center justify-between w-[116%] pt-4">
                    <div className="map-item flex flex-col">
                      <div className="about-map flex flex-col">
                        <div className="map-tag">
                          {t("ventajas.option1title")}
                        </div>
                        <div className="map-desc">{t("ventajas.option1")}</div>
                      </div>
                    </div>
                    <div className="map-item flex flex-col">
                      <div className="about-map flex flex-col">
                        <div className="map-tag">STAKING</div>
                        <div className="map-desc">{t("ventajas.option2")}</div>
                      </div>
                    </div>
                    <div className="map-item flex flex-col">
                      <div className="about-map flex flex-col">
                        <div className="map-tag">NFT</div>
                        <div className="map-desc">{t("ventajas.option3")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="road-map flex pt-32 w-[40%]">
                <div className="line w-full flex"></div>
                <div className="my-map w-full flex items-center justify-between flex-col">
                  <div className="flex items-center justify-between w-full">
                    <div className="numb">4</div>
                    <div className="numb">5</div>
                  </div>
                  <div className="flex items-center justify-between w-[116%] pt-4">
                    <div className="map-item flex flex-col">
                      <div className="about-map flex flex-col">
                        <div className="map-tag">AIRDROP</div>
                        <div className="map-desc">{t("ventajas.option4")}</div>
                      </div>
                    </div>
                    <div className="map-item flex flex-col">
                      <div className="about-map flex flex-col">
                        <div className="map-tag">HOLD</div>
                        <div className="map-desc">{t("ventajas.option5")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="video-sec flex items-center justify-center">
            <iframe
              width="560"
              height="315"
              src={
                currentLanguageCode === "es"
                  ? "https://www.youtube.com/embed/N4cFcT27Q3Q?controls=0"
                  : "https://www.youtube.com/embed/CQy4iwBFG8A"
              }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="chart-block flex items-center justify-center flex-col">
            <span className="s-1">{t("ventajas.desc2")}</span>
            <img
              className="tokenchart-img"
              src="./images/chart.svg"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
            <img
              className="tokenchart-img-mobile"
              src="./images/chartMobile.svg"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      <div className="company-info flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="our-team flex-col aic">
            <div className="page-tag">{t("extra.ourteam")}</div>
            <div className="offers  flex aic">
              <div className="block wrapper flex aic">
                <Slider {...teamSetting}>
                  <div>
                    <div className="team-wrap">
                      {teamGroup1.map((item, index) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          className="team-card flex flex-col items-center"
                          key={index}
                        >
                          <img
                            alt="prop tag"
                            src={item.img}
                            className="team-img"
                          />
                          <div className="member-info flex flex-col">
                            <div className="member-name">{item.name}</div>
                            <div
                              className="member-
                              designation"
                            >
                              {item.designation}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div className="team-wrap">
                      {teamGroup2.map((item, index) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className="team-card flex flex-col items-center"
                        >
                          <img
                            alt="prop tag"
                            src={item.img}
                            className="team-img"
                          />
                          <div className="member-info flex flex-col">
                            <div className="member-name">{item.name}</div>
                            <div
                              className="member-
                              designation"
                            >
                              {item.designation}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className="team-wrap">
                      {teamGroup3.map((item, index) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className="team-card flex flex-col items-center"
                        >
                          <img
                            alt="prop tag"
                            src={item.img}
                            className="team-img"
                          />
                          <div className="member-info flex flex-col">
                            <div className="member-name">{item.name}</div>
                            <div
                              className="member-
                              designation"
                            >
                              {item.designation}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className="team-wrap">
                      {teamGroup4.map((item, index) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                          className="team-card flex flex-col items-center"
                        >
                          <img
                            alt="prop tag"
                            src={item.img}
                            className="team-img"
                          />
                          <div className="member-info flex flex-col">
                            <div className="member-name">{item.name}</div>
                            <div
                              className="member-
                              designation"
                            >
                              {item.designation}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            {/* <div className="offers2  flex aic">
              <div className="block wrapper flex aic">
                <Slider {...teamSetting2}>
                  {teamGroup1.map((item, index) => (
                    <div key={index}>
                      <div
                        className="team-card flex flex-col items-center"
                        onClick={(e) => {
                          setOpen(true);
                          setUserInfo(item);
                        }}
                      >
                        <img alt="prop tag"src={item.img} className="team-img" />
                        <div className="member-info flex flex-col">
                          <div className="member-name">{item.name}</div>
                          <div
                            className="member-
                          designation"
                          >
                            {item.designation}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="hero-sec flex">
        <div
          className="hm-bg flex "
          style={{ backgroundImage: `url("./images/home-bg1.svg")` }}
        >
          <div className="wrap wrapWidth flex items-center justify-center">
            <div className="left flex" style={{ maxWidth: "500px" }}>
              <div className="left-block flex flex-col items-center justify-center w-full">
                <div className="slogan flex flex-col items-center">
                  <div className="flex items-start flex-col">
                    <span
                      className="s-1 flex"
                      style={{ fontSize: "32px", lineHeight: "28px" }}
                    >
                      {" "}
                      {t("biotech.title1")}
                    </span>
                    <span
                      className="s-1 flex"
                      style={{ fontSize: "32px", lineHeight: "28px" }}
                    >
                      {" "}
                      {t("biotech.title2")}
                    </span>
                    <span
                      className="s-1 flex"
                      style={{ fontSize: "32px", lineHeight: "28px" }}
                    >
                      {" "}
                      {t("biotech.title3")}
                    </span>
                  </div>
                </div>
                <div className="homebg-blk flex w-full">
                  <span
                    className="s-2  "
                    style={{
                      textAlign: "start",
                      fontSize: "32px",
                      lineHeight: "32px",
                      fontWeight: "bold",
                      color: "#fff",
                      width: "50%",
                      margin: "40px 0px",
                    }}
                  >
                    {t("biotech.desc")}
                  </span>
                </div>
                <div className="action flex w-full mt-6 flex-col  biotechsection1">
                  <a
                    href="https://www.hbmpartners.com/media/docs/industry-reports/HBM-Pharma-Biotech-M-A-Report-2020.pdf"
                    className="btn button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LINK
                  </a>
                  <br />
                  <br />
                  <br />
                  <span
                    className="s-2 flex "
                    style={{
                      fontSize: "20px",
                      margin: "0px",
                      lineHeight: "20px",
                    }}
                  >
                    {t("biotech.fuente")}
                  </span>
                  <span
                    className="s-2 flex"
                    style={{
                      fontSize: "20px",
                      margin: "0px",
                      lineHeight: "20px",
                    }}
                  >
                    HBM Pharma/Biotech M&A Report 2020.
                  </span>
                  <span
                    className="s-2 flex"
                    style={{ fontSize: "20px", lineHeight: "20px" }}
                  >
                    Pharma-Biotech-M-A-Transactions-2005-2019-Excel.xlsx
                  </span>
                </div>
              </div>
            </div>
            <div className="right flex items-center justify-end">
              <img
                className="img-block"
                src="./images/biotech.png"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
              <div className="action flex w-full mt-6 flex-col  biotechsection2">
                <a
                  href="https://www.hbmpartners.com/media/docs/industry-reports/HBM-Pharma-Biotech-M-A-Report-2020.pdf"
                  className="btn button"
                  target="_blank"
                  rel="noreferrer"
                >
                  LINK
                </a>
                <br />
                <br />
                <br />
                <span
                  className="s-2 flex "
                  style={{
                    fontSize: "20px",
                    margin: "0px",
                    lineHeight: "20px",
                  }}
                >
                  {t("biotech.fuente")}
                </span>
                <span
                  className="s-2 flex"
                  style={{
                    fontSize: "20px",
                    margin: "0px",
                    lineHeight: "20px",
                  }}
                >
                  HBM Pharma/Biotech M&A Report 2020.
                </span>
                <span
                  className="s-2 flex"
                  style={{ fontSize: "20px", lineHeight: "20px" }}
                >
                  Pharma-Biotech-M-A-Transactions-2005-2019-Excel.xlsx
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company-info flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="page-tag"> {t("extra.nosotros")}</div>

          <div className="news-sec flex flex-col">
            <div className="page-tag">{t()}</div>
            <div className="offers  flex aic">
              <div className="block wrapper flex aic">
                <Slider {...newsSetting}>
                  {newsList.map((item, index) => (
                    <div key={index}>
                      <div className="news-wrap flex">
                        <div className="news-card flex flex-col">
                          <img
                            src={item.img}
                            className="news-img"
                            style={{ objectFit: "contain" }}
                          />
                          <div className="news-action flex items-center justify-center">
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                              className="btn button"
                            >
                              {t("extra.leer")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <div className="collaboration flex flex-col">
            <div className="flex flex-col pb-14">
              <div className="page-tag">{t("collab.title1")}</div>
              <div className="page-tag2">{t("collab.title2")}</div>
              <div className="page-desc">{t("collab.title3")}</div>
            </div>
            <div className="collaboration-wrap">
              {collaborationList.map((item, index) => (
                <div
                  key={index}
                  className="collab-card flex items-center justify-center"
                >
                  <img alt="prop tag" src={item.img} className="img" />
                </div>
              ))}
            </div>
          </div>

          <div className="page-tag"> {t("premios.title")}</div>
          <div className="news-sec flex flex-col">
            <div className="offers  flex aic">
              <div
                className="block wrapper flex aic"
                style={{
                  flexWrap: "wrap",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="news-wrap flex">
                  <div className="news-card flex flex-col">
                    <img
                      src={"./images/premio1.png"}
                      className="news-img"
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                    />
                    <div className="news-action flex items-center justify-center text-center">
                      {t("premios.premio1")}
                    </div>
                  </div>
                </div>
                <div className="news-wrap flex">
                  <div className="news-card flex flex-col">
                    <img
                      src={"./images/premio2.png"}
                      className="news-img"
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                    />
                    <div className="news-action flex items-center justify-center text-center">
                      {t("premios.premio2")}
                    </div>
                  </div>
                </div>
                <div className="news-wrap flex">
                  <div className="news-card flex flex-col">
                    <img
                      src={"./images/premio3.png"}
                      className="news-img"
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                    />
                    <div className="news-action flex items-center justify-center text-center">
                      {t("premios.premio3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-sec flex">
        <div className="wrap wrapWidth flex flex-col  items-center gap-[10px]">
          <div className="page-tag">{t("extra.valores")}</div>
          <div
            className="page-desc"
            style={{ color: "#ffff", fontWeight: "bold", maxWidth: "900px" }}
          >
            {t("extra.valoresdesc")}
            <br />
            <br />
            {t("extra.valoresdesc2")}
          </div>
          <div className="footer-comp">
            <div className="footer-camp-card flex flex-col justify-center items-center">
              <img
                alt="prop tag"
                src="./images/Illust1.png"
                className="ill-img"
              />
              <div className="ill-name"> {t("extra.value1")}</div>
            </div>
            <div className="footer-camp-card flex flex-col justify-center items-center">
              <img
                alt="prop tag"
                src="./images/Illust2.png"
                className="ill-img"
              />
              <div className="ill-name"> {t("extra.value2")}</div>
            </div>
            <div className="footer-camp-card flex flex-col justify-center items-center">
              <img
                alt="prop tag"
                src="./images/Illust3.png"
                className="ill-img"
              />
              <div className="ill-name"> {t("extra.value3")}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <MemeberDetail setOpen={setOpen} userData={userInfo} />
      </Modal>
    </div>
  );
};

export default MainPage;
