import React, { useContext } from "react";
import { AlertIcon } from "../../assets";
import { WalletWeb3Context } from "../../context/WalletWeb3Context";
import { setWalletNetwork } from "../../store/Reducers/Wallet/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
const UserModal = ({ account, isAdmin, disconnectWallet, onClose }) => {
  const dispatch = useDispatch();
  const { library } = useContext(WalletWeb3Context);
  const { isWrongNetwork } = useSelector(({ wallet }) => wallet);
  const disconnectWalletHandler = () => {
    onClose();
    setTimeout(() => {
      disconnectWallet();
    }, 1000);
  };
  return (
    <>
      <div
        style={{ background: "rgba(159, 115, 230, 0.2)" }}
        className="modal--container--icon"
      >
        <AlertIcon />
      </div>
      <div className="modal--container--wallet">
        <div className="modal--container--wallet--title"> Conectado con :</div>
        <div className="modal--container--wallet--address">
          <div className="modal--container--wallet--connected" />
          <div>{account}</div>
        </div>
      </div>
      {isWrongNetwork && (
        <>
          <div className="modal--container--divider" />
          <button
            className="modal--container--button-admin"
            onClick={() => setWalletNetwork(dispatch, library)}
          >
            Cambiar Network
          </button>
          <div className="modal--container--divider" />
        </>
      )}
      {isAdmin && (
        <>
          <div className="modal--container--divider" />
          <Link to="/admin" onClick={() => onClose()}>
            <button className="modal--container--button-admin">ADMIN</button>
          </Link>
          <div className="modal--container--divider" />
        </>
      )}
      <button
        className="modal--container--button-disconnect"
        onClick={() => disconnectWalletHandler()}
      >
        Desconectar
      </button>
    </>
  );
};

export default UserModal;
