import React, { memo, useContext } from "react";
import { releaseClaim } from "../..//store/Reducers/Phase/actions";
import { useDispatch } from "react-redux";
import { WalletWeb3Context } from "../../context/WalletWeb3Context";

const TableBodyOption = ({ row }) => {
  const dispatch = useDispatch();
  const { saleContract } = useContext(WalletWeb3Context);

  const isDateHigherThanToday = (date) => {
    const today = new Date();
    const dateToCompare = new Date(date);
    return dateToCompare < today;
  };
  return (
    <div className="table-body-cell">
      <div className=" table-body-col">{row.initAmout}</div>
      <div className=" table-body-col">
        {row.initAmout - row.remainingTokens}
      </div>
      <div className=" table-body-col  ">
        {row.date}
        {row.releaseDates.map((o, i) => {
          if (o === row.date) {
            return <div key={i}>{row.percents[i]}%</div>;
          }
        })}
      </div>
      <div className=" table-body-col  ">
        {isDateHigherThanToday(row.date) && (
          <button
            type="button"
            className="modal--container--button-green"
            style={{ width: "100px", padding: "0px" }}
            onClick={() => releaseClaim(dispatch, row.id, saleContract)}
          >
            CLAIM
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(TableBodyOption);
