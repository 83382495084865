import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const { isAdmin } = useSelector(({ adminPanel }) => adminPanel);
  if (isAdmin) {
    return <>{children}</>;
  }
  return <Navigate to="/" state={{ from: location }} replace />;
};

export default PrivateRoute;
