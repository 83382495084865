/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDispatcher,
  setDateEnd,
  resetMessage,
} from "../../../store/Reducers/AdminPanel/actions";

const Configuracion = ({ saleContract }) => {
  const dispatch = useDispatch();
  const { isLoadingAdmin, onsuccessAdmin, error } = useSelector(
    ({ adminPanel }) => adminPanel
  );
  useEffect(() => {
    resetMessage(dispatch);
  }, []);

  const [isDispatcher, setisDispatcher] = useState("");
  const [isDateEnd, setisDateEnd] = useState("");
  const onchangeHandleDispatcher = (e) => {
    const { value } = e.target;
    setisDispatcher(() => value);
  };
  const onchangeHandleDateEnd = (e) => {
    const { value } = e.target;
    setisDateEnd(() => value);
  };
  const onSubmitDispatcher = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDispatcher(dispatch, saleContract, isDispatcher);
  };
  const onSubmitDateEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDateEnd(dispatch, saleContract, isDateEnd);
  };
  return (
    <>
      <form className="adminpanel-form" onSubmit={onSubmitDispatcher}>
        <div className="adminpanel-input-container ">
          <div className="adminpanel-input-title">Cambiar dispatcher</div>
          <input
            onChange={onchangeHandleDispatcher}
            type="text"
            value={isDispatcher}
            className="adminpanel-input-input"
          />
        </div>
        <div className="adminpanel-box-button">
          <button
            disabled={isLoadingAdmin}
            className="modal--container--button-green"
          >
            {isLoadingAdmin ? "Cargando..." : "Cambiar"}
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
      <form className="adminpanel-form" onSubmit={onSubmitDateEnd}>
        <div className="adminpanel-input-container ">
          <div className="adminpanel-input-title">
            Cambiar Fecha de Finalizacion
          </div>
          <input
            onChange={onchangeHandleDateEnd}
            type="date"
            value={isDateEnd}
            className="adminpanel-input-date"
            required
            min={
              new Date(new Date().getTime() + 86400000)
                .toISOString()
                .split("T")[0]
            }
          />
        </div>
        <div className="adminpanel-box-button">
          <button
            disabled={isLoadingAdmin}
            className="modal--container--button-green"
          >
            {isLoadingAdmin ? "Cargando..." : "Cambiar"}
          </button>
        </div>
        <div className="adminpanel-box-option-value">{onsuccessAdmin}</div>
        {error}
      </form>
    </>
  );
};

export default Configuracion;
