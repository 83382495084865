import React, { useState } from "react";

const Disclaimer = () => {
  const [isOpen, setIsOpen] = useState(true);
  const currentLanguageCode = localStorage.getItem("i18nextLng") || "es";
  return (
    <div
      style={{
        padding: "16px 24px",
        width: "100%",
        background: "#ee3395",
        display: isOpen ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "bold",
        fontSize: "0.8rem",
        position: "sticky",
        top: "0",
        zIndex: "5",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "24px",
          width: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "5px",
          right: "5px",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(false)}
      >
        <svg
          clipRule="evenodd"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
        >
          <path
            d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z"
            fillRule="nonzero"
          />
        </svg>
      </div>
      <div style={{ maxWidth: "900px" }}>
        {currentLanguageCode === "es" ? (
          <>
            La inversión en criptoactivos no está regulada, puede no ser
            adecuada para inversores minoristas y perderse la totalidad del
            importe invertido. Es importante leer y comprender los riesgos de
            esta inversión que se explican detalladamente en esta{" "}
            <a
              href="https://keyzell.com/wp-content/uploads/2022/08/Risk-Disclosure-Spain.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              ubicación
            </a>
          </>
        ) : (
          <>
            Investment in crypto assets is not regulated, may not be suitable
            for retail investors and the entire amount invested may be lost. It
            is important to read and understand the risks of this investment
            that are explained in detail in this{" "}
            <a
              href="https://keyzell.com/wp-content/uploads/2022/08/Risk-Disclosure-English.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              location
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Disclaimer;
