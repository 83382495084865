const LoadingIcon = () => {
  return (
    <svg
      className="rotating"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2 12C23.1941 12 24.0138 11.1896 23.8653 10.2067C23.5323 8.00343 22.59 5.9236 21.1283 4.21059C19.2747 2.03849 16.7074 0.598516 13.8875 0.149376C11.0676 -0.299765 8.17997 0.271364 5.74336 1.76015C3.30675 3.24895 1.48088 5.55782 0.593783 8.27197C-0.293316 10.9861 -0.183502 13.9277 0.903496 16.5681C1.99049 19.2086 3.98343 21.3749 6.52426 22.6778C9.06509 23.9808 11.9873 24.335 14.7658 23.6769C16.9571 23.1579 18.9458 22.036 20.5158 20.4547C21.2162 19.7492 21.0333 18.6112 20.2311 18.0241C19.4289 17.4369 18.3128 17.63 17.5685 18.289C16.5384 19.2011 15.2927 19.8525 13.9361 20.1738C11.9911 20.6345 9.94556 20.3866 8.16698 19.4745C6.3884 18.5624 4.99335 17.046 4.23245 15.1977C3.47155 13.3494 3.39468 11.2903 4.01565 9.39038C4.63662 7.49047 5.91472 5.87426 7.62035 4.83211C9.32598 3.78995 11.3473 3.39017 13.3213 3.70456C15.2952 4.01896 17.0923 5.02694 18.3898 6.54741C19.2948 7.60795 19.9153 8.86933 20.2079 10.2137C20.4193 11.1851 21.2059 12 22.2 12Z"
        fill="url(#paint0_angular_15_19904)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_15_19904"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(48.3665) scale(12.0416)"
        >
          <stop offset="0.866457" stopColor="#6ABB7C" />
          <stop offset="0.969827" stopColor="#6ABB7C" stopOpacity="0.29" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default LoadingIcon;
