export const shortenAddress = (address) => {
  if (!address) return "error";
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
};
export const shortenAddressLong = (address) => {
  if (!address) return "error";
  return `${address.slice(0, 10)}...${address.slice(address.length - 9)}`;
};
export const localeString = (number) => {
  return parseFloat(number).toPrecision().toLocaleString();
};
export const stringToSHA256 = async (string) => {
  if (!string) return "error";
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, "0"))
    .join("");
  return hashHex.toUpperCase();
};
export const dollarToEuro = (value) => {
  return parseFloat(value * parseFloat(0.98).toFixed(18));
};
export const euroToDollar = (value) => {
  return parseFloat(value / 0.98);
};

export const saveAffiliateTransaction = (referralId, amount, wallet) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    userId: referralId,
    quantity: amount,
    wallet,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `https://blockimpulse-affiliate-platform.vercel.app/api/transactions`,
    requestOptions
  )
    .then((res) => res.json())
    .then((res) => console.log(res))
    .catch((error) => console.warn(error));
};
export const formatNumbers = (value) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
};
