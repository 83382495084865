import React, { memo } from "react";

const TableHeaderOption = ({ option }) => {
  return (
    <div className={`table-header-cell aic anim hover`}>
      <div className="table-header-cell-body aic">
        <div className="table-header-cell-body-text">{option}</div>
      </div>
    </div>
  );
};

export default memo(TableHeaderOption);
