/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setWithdraw,
  setWithdrawBUSD,
  resetMessage,
} from "../../../store/Reducers/AdminPanel/actions";

const Retiro = ({ saleContract }) => {
  const { isLoadingAdmin, onsuccessAdmin, error } = useSelector(
    ({ adminPanel }) => adminPanel
  );

  const dispatch = useDispatch();
  useEffect(() => {
    resetMessage(dispatch);
  }, []);
  const [isdataForm, setisdataForm] = useState({
    address: "",
    amount: "",
  });
  const onchangeHandler = (e) => {
    const { name, value } = e.target;
    setisdataForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setWithdraw(dispatch, saleContract, isdataForm);
  };
  const onSubmitbusd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setWithdrawBUSD(dispatch, saleContract, isdataForm);
  };
  return (
    <form className="adminpanel-form">
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Wallets</div>
        <input
          onChange={onchangeHandler}
          type="text"
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="Ingresa Wallet"
          value={isdataForm.address}
          name="address"
          required
          pattern="[a-zA-Z0-9]{42,}"
        />
      </div>
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Cantidad a retirar</div>
        <input
          onChange={onchangeHandler}
          type="number"
          className="adminpanel-input-input"
          value={isdataForm.amount}
          placeholder="Cantidad de BNB a retirar"
          name="amount"
          step={0.001}
          required
          min={0}
          pattern="\d*"
        />
      </div>

      <div
        className="adminpanel-box-button"
        style={{ gap: "8px", flexWrap: "wrap" }}
      >
        <button
          type="button"
          disabled={isLoadingAdmin}
          className="modal--container--button-green"
          onClick={(e) => onSubmit(e)}
        >
          {isLoadingAdmin ? "Cargando..." : "Retirar BNB"}
        </button>
        <button
          type="button"
          disabled={isLoadingAdmin}
          className="modal--container--button-green"
          onClick={(e) => onSubmitbusd(e)}
        >
          {isLoadingAdmin ? "Cargando..." : "Retirar BUSD"}
        </button>
      </div>
      <div className="adminpanel-box-option-value">{onsuccessAdmin}</div>
      {error}
    </form>
  );
};

export default Retiro;
