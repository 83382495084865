import React, { useState, useContext } from "react";
import Modal from "./Modal";
import UserModal from "./UserModal";
import WhitePaper from "./WhitePaper";
import { Link } from "react-router-dom";
import { WalletWeb3Context } from "../context/WalletWeb3Context";
import { useSelector } from "react-redux";
import { shortenAddress, shortenAddressLong } from "../utils";
import i18next from "i18next";
import { useCountdown } from "./useCountdown";
const Header = () => {
  const [days, hours, minutes, seconds] = useCountdown(
    "2022-08-29T00:00:00.000Z"
  );
  const currentLanguageCode = localStorage.getItem("i18nextLng") || "es";
  const languageHandle = () => {
    if (currentLanguageCode === "es") {
      i18next.changeLanguage("en");
    } else {
      i18next.changeLanguage("es");
    }
  };

  const { connectWallet, disconnectWallet } = useContext(WalletWeb3Context);
  const { isAdmin } = useSelector(({ adminPanel }) => adminPanel);
  const [openUserModal, setopenUserModal] = useState(false);
  const [openWhitePaper, setopenWhitePaper] = useState(false);
  const { walletAddress } = useSelector(({ wallet }) => wallet);
  const userModalHandler = () => {
    if (!!walletAddress) {
      setopenUserModal(() => true);
    } else {
      connectWallet();
      userModalClose();
    }
  };

  const userModalClose = () => {
    setopenUserModal(() => false);
  };
  const whitepaperModalClose = () => {
    setopenWhitePaper(() => false);
  };

  return (
    <>
      <div className="header-camp flex flex-col">
        {!!walletAddress && (
          <Modal visible={openUserModal} onClose={() => userModalClose()}>
            <UserModal
              account={shortenAddressLong(walletAddress)}
              isAdmin={isAdmin}
              disconnectWallet={disconnectWallet}
              onClose={userModalClose}
            />
          </Modal>
        )}
        <Modal visible={openWhitePaper} onClose={() => whitepaperModalClose()}>
          <WhitePaper />
        </Modal>

        <div className="wrapWidth wrap flex items-center">
          <div className="left ">
            <Link to={"/"}>
              <img
                alt="branding"
                src={
                  currentLanguageCode === "es"
                    ? "./images/brandEs.png"
                    : "./images/brandEn.png"
                }
                className="logo-img cursor-pointer"
              />
            </Link>
          </div>
          <div className="right flex items-center justify-end">
            <div className="action flex items-center">
              <div className="btn button" onClick={() => languageHandle()}>
                {currentLanguageCode === "es" ? "English" : "Español"}
              </div>
              <div
                className="btn button"
                onClick={() => setopenWhitePaper(() => true)}
              >
                White Paper
              </div>
              <a
                href={
                  currentLanguageCode === "es"
                    ? "https://keyzell.com/faq/"
                    : "https://keyzell.com/en/faq-en/"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="btn button"
              >
                FAQ
              </a>

              <div className="btn button ">
                {days + hours + minutes + seconds <= 0
                  ? "00:00:00:00"
                  : days + "d:" + hours + "h:" + minutes + "m:" + seconds + "s"}
              </div>
              <div className="btn button " onClick={() => userModalHandler()}>
                {!!walletAddress
                  ? shortenAddress(walletAddress)
                  : "Connect Wallet"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
