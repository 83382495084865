import React from "react";

const WhitePaper = () => {
  return (
    <>
      <a
        className="modal--container--button-admin hover-effect"
        href="https://keyzell.com/wp-content/uploads/2022/09/WhitePaper-KZ.-v2.0-EN_.pdf"
        target="_blank"
        rel="noreferrer"
      >
        English
      </a>
      <div className="modal--container--divider" />
      <a
        className="modal--container--button-admin hover-effect"
        href="https://keyzell.com/wp-content/uploads/2022/09/WhitePaper-KZ.-v2.0_.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Español
      </a>
    </>
  );
};

export default WhitePaper;
