import React, { useContext, useEffect, useState } from "react";
import { WalletWeb3Context } from "../context/WalletWeb3Context";
import { useSelector, useDispatch } from "react-redux";
import { getPhaseData } from "../store/Reducers/Phase/actions";
import { localeString, dollarToEuro } from "../utils/";
import Modal from "../components/Modal";
import BuyToken from "../components/BuyToken";
import BuyTokenBusd from "../components//BuyTokenBusd";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Disclaimer from "../components/Disclaimer";
import Table from "../components/Table";

const SecondPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isBuyTokenOpen, setisBuyTokenOpen] = useState(false);
  const [isBuyTokenBusdOpen, setisBuyTokenBusdOpen] = useState(false);
  const { connectWallet, saleContract } = useContext(WalletWeb3Context);
  const { walletAddress } = useSelector(({ wallet }) => wallet);
  const {
    isLoadingPhase,
    phasesData,
    comprasRealizadas,
    transactionHash,
    onsuccessPhase,
  } = useSelector(({ phase }) => phase);
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (saleContract !== null) {
      getPhaseData(dispatch, saleContract, walletAddress);
    }
  }, [
    saleContract,
    walletAddress,
    isBuyTokenOpen,
    isBuyTokenBusdOpen,
    transactionHash,
  ]);
  //
  const closePurchaseModal = () => {
    if (!isLoadingPhase) return setisBuyTokenOpen(() => false);
  };
  const closePurchaseBusdModal = () => {
    if (!isLoadingPhase) return setisBuyTokenBusdOpen(() => false);
  };
  const getNfts = () => {
    let sumTokens = comprasRealizadas.reduce(
      (n, { initAmout }) => n + parseFloat(initAmout),
      0
    );
    let totalNfts = parseInt(sumTokens / 100_000);
    return totalNfts;
  };

  return (
    <div className="second-page flex flex-col" style={{ minHeight: "100vh" }}>
      <Disclaimer />

      <Header />
      <div className="wrap wrapWidth flex">
        {!!walletAddress ? (
          <div className="blk flex flex-col">
            <Modal
              visible={isBuyTokenOpen}
              onClose={() => closePurchaseModal()}
              style={{ height: "600px" }}
            >
              <BuyToken />
            </Modal>
            <Modal
              visible={isBuyTokenBusdOpen}
              onClose={() => closePurchaseBusdModal()}
              style={{ height: "600px" }}
            >
              <BuyTokenBusd />
            </Modal>
            <div className="page-tag">
              {/* 
              {isLoadingPhase
                ? t("phase.cargando")
                : phasesData?.isPublic
                ? t("phase.public")
                : t("phase.priv")} */}

              {isLoadingPhase
                ? t("phase.cargando")
                : phasesData?.isPublic
                ? t("phase.priv")
                : t("phase.priv")}
            </div>

            <div className="item flex flex-col">
              <div className="lbl"> {t("phase.title1")}</div>
              <div className="val">
                {isLoadingPhase
                  ? t("phase.cargando")
                  : localeString(phasesData?.supply)}
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="lbl">{t("phase.precio")}</div>
              <div className="val">
                {isLoadingPhase ? (
                  t("phase.cargando")
                ) : (
                  <>{dollarToEuro(phasesData.price).toFixed(2)} &euro;</>
                )}
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="lbl">{t("phase.title2")}</div>
              <div className="val">
                {isLoadingPhase
                  ? t("phase.cargando")
                  : localeString(phasesData?.minimunEntry) + " K2Z"}
              </div>
              <div className="val">
                {isLoadingPhase ? (
                  t("phase.cargando")
                ) : (
                  <>
                    {localeString(
                      dollarToEuro(
                        phasesData.price * phasesData.minimunEntry
                      ).toFixed(5)
                    )}
                    &euro;
                  </>
                )}
              </div>
              {/* /////////////////////// */}

              {/* /////////////////////// */}
            </div>
            <div className="item flex flex-col">
              <div className="lbl"> {t("phase.title3")}</div>
              <div className="val">
                {isLoadingPhase
                  ? t("phase.cargando")
                  : localeString(phasesData?.maxEntry) + " K2Z"}
              </div>
              <div className="val">
                {isLoadingPhase ? (
                  t("phase.cargando")
                ) : (
                  <>
                    {localeString(
                      dollarToEuro(
                        phasesData.price * phasesData.maxEntry
                      ).toFixed(5)
                    )}
                    &euro;
                  </>
                )}
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="lbl"> {t("phase.title4")}</div>
              <div className="val">
                {isLoadingPhase ? t("phase.cargando") : t("phase.period2")}
                {/* : phasesData?.timelock + t("phase.day")} */}
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="lbl"> {t("phase.title5")}</div>
              <div className="val">12 {t("phase.period")}</div>
            </div>

            <div className="item flex item-center w-full justify-center gap-[8px]">
              {/* <button
                className="modal--container--button-admin"
                style={{
                  color: "white",
                }}
                onClick={() => setisBuyTokenOpen(() => true)}
              >
                BNB
              </button> */}
              <button
                className="modal--container--button-admin"
                style={{
                  color: "white",
                }}
                // onClick={() => setisBuyTokenBusdOpen(() => true)}
              >
                Proximamente
              </button>
            </div>
          </div>
        ) : (
          <div
            className="blk flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              color: "white !important",
            }}
          >
            <button
              className="modal--container--button-admin"
              onClick={connectWallet}
              style={{
                color: "white",
              }}
            >
              {t("phase.conectar")}
            </button>
          </div>
        )}
      </div>

      <div className="wrap wrapWidth flex">
        {!!walletAddress && (
          <div className="blk flex flex-col" style={{ overflow: "hidden" }}>
            <div className="page-tag"> {t("phase.compras")}</div>
            {comprasRealizadas.length > 0 ? (
              <>
                {!isLoadingPhase && (
                  <>
                    <Table tableData={comprasRealizadas} />
                    <br />
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {onsuccessPhase}
                      <div className="page-tag"> Total NFTS</div>
                      {getNfts()} NFTS
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="item flex flex-col">
                <div className="lbl">{t("phase.empty")}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondPage;
