import React, { useState, useContext } from "react";
import Modal from "../Modal";
import UserModal from "../UserModal";
import WhitePaper from "../WhitePaper";
import { WalletWeb3Context } from "../..//context/WalletWeb3Context";
import { useSelector } from "react-redux";
import { shortenAddress, shortenAddressLong } from "../../utils";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Footer = () => {
  const currentLanguageCode = localStorage.getItem("i18nextLng") || "es";

  const { t } = useTranslation();
  const languageHandle = (language) => {
    i18next.changeLanguage(language);
  };
  const { connectWallet, disconnectWallet } = useContext(WalletWeb3Context);
  const { isAdmin } = useSelector(({ adminPanel }) => adminPanel);
  const [openUserModal, setopenUserModal] = useState(false);
  const [openWhitePaper, setopenWhitePaper] = useState(false);
  const { walletAddress } = useSelector(({ wallet }) => wallet);
  const userModalHandler = () => {
    if (!!walletAddress) {
      setopenUserModal(() => true);
    } else {
      connectWallet();
      userModalClose();
    }
  };

  const userModalClose = () => {
    setopenUserModal(() => false);
  };
  const whitepaperModalClose = () => {
    setopenWhitePaper(() => false);
  };
  return (
    <div className="footer--container">
      {!!walletAddress && (
        <Modal visible={openUserModal} onClose={() => userModalClose()}>
          <UserModal
            account={shortenAddressLong(walletAddress)}
            isAdmin={isAdmin}
            disconnectWallet={disconnectWallet}
            onClose={userModalClose}
          />
        </Modal>
      )}
      <Modal visible={openWhitePaper} onClose={() => whitepaperModalClose()}>
        <WhitePaper />
      </Modal>
      <div className="footer-icon ">
        <img src="./images/logoSmall.png" className="footer-icon-icon" alt="" />
      </div>
      <div className="footer-text ">{t("footer.title1")}</div>
      <div className="footer-buttons">
        <div
          className="btn button"
          onClick={() => setopenWhitePaper(() => true)}
        >
          White Paper
        </div>
        <div className="btn button " onClick={() => userModalHandler()}>
          {!!walletAddress ? shortenAddress(walletAddress) : "Connect Wallet"}
        </div>
      </div>
      <div className="footer-buttons" style={{ gap: "32px" }}>
        <a
          href="https://keyzell.com/"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          {t("footer.tab1")}
        </a>
        <a
          href="https://keyzell.com/careers/"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          {t("footer.tab2")}
        </a>
        <a
          href="https://keyzell.com/contacto/"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          {t("footer.tab3")}
        </a>
      </div>
      <div className="footer-buttons" style={{ gap: "32px" }}>
        <a
          href="https://www.facebook.com/K2Zcrypto-104884012094674/"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          <img
            src="./images/facebook.svg"
            className="footer-icon-icon"
            alt=""
          />
        </a>
        <a
          href="https://twitter.com/k2Zcrypto"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          <img src="./images/twitter.svg" className="footer-icon-icon" alt="" />
        </a>
        <a
          href="https://www.instagram.com/k2zcrypto/"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
          style={{ height: "18px" }}
        >
          <img
            src="./images/instagram.svg"
            className="footer-icon-icon"
            alt=""
          />
        </a>
        <a
          href="https://www.linkedin.com/company/keyzell"
          target="_blank"
          rel="noreferrer"
          className="footer-link"
        >
          <img
            src="./images/linkedin.svg"
            className="footer-icon-icon"
            alt=""
          />
        </a>
      </div>
      <div className="footer-buttons" style={{ gap: "32px" }}>
        <a href="/privacidad.docx" download className="footer-link">
          {t("footer.priv")}
        </a>
        <a href="/aviso.docx" download className="footer-link">
          {t("footer.legal")}
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={
            currentLanguageCode === "es"
              ? "https://keyzell.com/wp-content/uploads/2022/08/Risk-Disclosure-Spain.pdf"
              : "https://keyzell.com/wp-content/uploads/2022/08/Risk-Disclosure-English.pdf"
          }
          className="footer-link"
        >
          {t("footer.riesgo")}
        </a>
        <a
          href={
            currentLanguageCode === "es"
              ? "https://keyzell.com/faq/"
              : "https://keyzell.com/en/faq-en/"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          FAQ
        </a>
        <a href="/cookies.docx" download className="footer-link">
          {t("footer.cookie")}
        </a>
      </div>
      <div className="footer-buttons" style={{ gap: "32px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {["es", "en"].map((o) => {
            return (
              <div
                key={o}
                className={
                  currentLanguageCode === o
                    ? "lang-selected footer-text"
                    : "lang-not-selected footer-text"
                }
                onClick={() => languageHandle(o)}
              >
                {o.toUpperCase()}
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer-buttons" style={{ gap: "32px" }}>
        <div className="footer-text" style={{ fontSize: "18px" }}>
          {t("extra.footer")}
        </div>
      </div>
    </div>
  );
};

export default Footer;
