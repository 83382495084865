import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SuccessIcon, ErrorIcon, LoadingIcon } from "../../assets";
import { setBuyTokenBusd } from "../../store/Reducers/Phase/actions";
import { WalletWeb3Context } from "../../context/WalletWeb3Context";
import {
  shortenAddress,
  localeString,
  dollarToEuro,
  formatNumbers,
} from "../../utils";
import { useTranslation } from "react-i18next";
const BuyTokenBusd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { saleContract, library } = useContext(WalletWeb3Context);
  const [Option, setOption] = useState("warning");
  const [isValue, setisValue] = useState("");

  const { isLoadingPhase, error, onsuccessPhase, phasesData, transactionHash } =
    useSelector(({ phase }) => phase);
  const { walletAddress } = useSelector(({ wallet }) => wallet);
  useEffect(() => {
    if (error) {
      setOption("error");
    }
    if (onsuccessPhase) {
      setOption("success");
    }
  }, [error, onsuccessPhase]);
  //
  const backgroundChecker = (option) => {
    if (option === "warning") {
      let color = { background: "rgba(159, 115, 230, 0.2)" };
      return color;
    }
    if (option === "success") {
      let color = { background: "rgba(48, 179, 140,0.2)" };
      return color;
    }
    if (option === "error") {
      let color = { background: "rgba(228, 95, 53, 0.2)" };
      return color;
    }
  };
  //
  const onChangeHandler = (e) => {
    const { value } = e.target;
    setisValue(() => value);
  };

  const purchaseToken = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBuyTokenBusd(
      dispatch,
      saleContract,
      isValue,
      walletAddress,
      library,
      phasesData.price
    );
  };
  return (
    <form className="flex jcc aic flex-col " onSubmit={purchaseToken}>
      <div style={backgroundChecker(Option)} className="modal--container--icon">
        {Option === "warning" && (
          <img
            src="./images/logo.png"
            draggable="false"
            style={{ height: "75px" }}
          />
        )}
        {Option === "success" && <SuccessIcon />}
        {Option === "error" && <ErrorIcon />}
      </div>
      <div className="modal--container--info">
        <div className="modal--container--info--title">
          {Option === "warning" && t("purchase.title1")}
          {Option === "error" && t("purchase.title2")}
          {Option === "success" && onsuccessPhase}
        </div>
        {Option === "warning" && (
          <div className="modal--container--info--input">
            <input
              style={{ color: "black" }}
              placeholder="0.00"
              className="modal--container--info--input-number"
              type="number"
              value={isValue}
              onChange={onChangeHandler}
              name="isValue"
              aria-disabled={false}
              step={0.001}
              required
              disabled={isLoadingPhase}
              pattern="\d*"
              min={phasesData.minimunEntry}
              max={phasesData.maxEntry}
            />
            <div className="modal--container--info--input-icon">K2Z</div>
          </div>
        )}
        <div className="modal--container--info--subtitle">
          {Option === "warning" &&
            ` ${t("purchase.warning2")} ${
              isNaN(parseFloat(isValue) * parseFloat(phasesData.currentPrice))
                ? "0"
                : localeString(
                    dollarToEuro(
                      parseFloat(isValue) * phasesData.currentPrice
                    ).toFixed(5)
                  )
            } €`}
          {Option === "warning" && (
            <>
              <br />
              {t("purchase.amount")} {formatNumbers(phasesData.minimunEntry)}
              {" K2Z "}
              {`(~${localeString(
                (phasesData.minimunEntry * phasesData.price).toFixed(2)
              )} BUSD)`}
              {"  & "}
              {formatNumbers(phasesData.maxEntry)}
              {" K2Z "}
              {`(~${localeString(
                (phasesData.maxEntry * phasesData.price).toFixed(2)
              )} BUSD)`}
            </>
          )}
          {Option === "error" && error}
          {Option === "success" && (
            <>
              {t("purchase.revisar")}
              <br />
              <a
                href={
                  process.env.REACT_APP_NETWORK_ENV === "mainnet"
                    ? `${process.env.REACT_APP_MAINNET_URL}/tx/${transactionHash}`
                    : `${process.env.REACT_APP_TESTNET_URL}/tx/${transactionHash}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {shortenAddress(transactionHash)}
              </a>
            </>
          )}
        </div>
        {Option === "warning" && (
          <div className="modal--container--info--input">
            <input
              style={{ color: "black" }}
              className="modal--container--info--input-number"
              type="number"
              value={parseFloat(isValue * phasesData.price).toFixed(8)}
              aria-disabled={false}
              disabled
            />
            <div className="modal--container--info--input-icon">BUSD</div>
          </div>
        )}
        {Option === "error" && (
          <button
            className="modal--container--button-error"
            onClick={() => setOption("warning")}
            disabled={isLoadingPhase}
            type="button"
          >
            {t("purchase.intentar")}
          </button>
        )}
        {Option === "success" && (
          <button
            type="button"
            className="modal--container--button-green"
            onClick={() => setOption("warning")}
            disabled={isLoadingPhase}
          >
            {t("purchase.comprar")}
          </button>
        )}
        {Option === "warning" && (
          <button
            disabled={isLoadingPhase}
            className="modal--container--button"
            type="submit"
          >
            {isLoadingPhase ? (
              <>
                <LoadingIcon />
                {t("purchase.cargando")}
              </>
            ) : (
              t("purchase.comprar")
            )}
          </button>
        )}
      </div>
    </form>
  );
};

export default BuyTokenBusd;
