/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdminPhase,
  resetMessage,
} from "../../../store/Reducers/AdminPanel/actions";

const Ventas = ({ saleContract, library }) => {
  const dispatch = useDispatch();
  const { isLoadingAdmin, onsuccessAdmin, error } = useSelector(
    ({ adminPanel }) => adminPanel
  );
  useEffect(() => {
    resetMessage(dispatch);
  }, []);

  const [isdataForm, setisdataForm] = useState({
    supply: "",
    minEntry: "",
    maxEntry: "",
    price: "",
    endAt: "",
    timelock: "",
    timesToRelease: "",
    percentsToRelease: "",
    phaseType: true,
  });
  const onchangeHandler = (e) => {
    const { name, value } = e.target;
    setisdataForm((prev) => ({
      ...prev,
      [name]: name === "phaseType" ? e.target.checked : value,
    }));
  };
  const onchangeHandlertimesToRelease = (e) => {
    const { name, value } = e.target;
    setisdataForm((prev) => ({
      ...prev,
      [name]: value === "0" ? "" : value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAdminPhase(dispatch, saleContract, isdataForm, library);
  };
  return (
    <form className="adminpanel-form" onSubmit={onSubmit}>
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Cantidad a vender</div>
        <input
          onChange={onchangeHandler}
          type="number"
          name="supply"
          value={isdataForm.supply}
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="ingrese un numero"
          step={1}
          required
          min={0}
          pattern="\d*"
        />
      </div>

      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Mīnimo de compra</div>
        <input
          onChange={onchangeHandler}
          type="number"
          name="minEntry"
          value={isdataForm.minEntry}
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="Ej: 1 token"
          step={1}
          required
          min={0}
          pattern="\d*"
        />
      </div>
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Máximo de compra</div>
        <input
          onChange={onchangeHandler}
          type="number"
          name="maxEntry"
          value={isdataForm.maxEntry}
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="Ej: 1 token"
          step={1}
          required
          min={0}
          pattern="\d*"
        />
      </div>

      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Precio en Euros</div>
        <input
          onChange={onchangeHandler}
          type="number"
          value={isdataForm.price}
          name="price"
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="€"
          step={0.0000001}
          required
          min={0}
          pattern="\d*"
        />
      </div>

      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Fecha de finalización</div>
        <input
          onChange={onchangeHandler}
          type="date"
          className="adminpanel-input-date"
          aria-disabled={false}
          value={isdataForm.endAt}
          name="endAt"
          required
          min={
            new Date(new Date().getTime() + 86400000)
              .toISOString()
              .split("T")[0]
          }
        />
      </div>

      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Días de bloqueo</div>
        <input
          onChange={onchangeHandler}
          type="number"
          className="adminpanel-input-input"
          placeholder="Dias"
          value={isdataForm.timelock}
          name="timelock"
          step={1}
          required
          min={0}
          pattern="\d*"
        />
      </div>

      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Días de desbloqueo</div>
        <input
          onChange={onchangeHandlertimesToRelease}
          type="text"
          className="adminpanel-input-input"
          aria-disabled={false}
          placeholder="1,2"
          value={isdataForm.timesToRelease}
          name="timesToRelease"
          required
        />
        <div className="adminpanel-input-info">
          Si deseas mas de una separalas con comas
        </div>
      </div>
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Porcentajes de desbloqueo</div>
        <input
          onChange={onchangeHandler}
          type="text"
          className="adminpanel-input-input"
          placeholder="%"
          value={isdataForm.percentsToRelease}
          name="percentsToRelease"
          required
        />
        <div className="adminpanel-input-info">
          Si deseas mas de una separalas con comas
        </div>
      </div>
      <div className="adminpanel-input-container ">
        <div className="adminpanel-input-title">Tipo de fase</div>
        <div className="flex" style={{ gap: "8px" }}>
          <input
            type="checkbox"
            name="phaseType"
            checked={isdataForm.phaseType}
            value={isdataForm.phaseType}
            onChange={onchangeHandler}
          />
          <div className="adminpanel-input-info" style={{ fontSize: "18px" }}>
            {!isdataForm.phaseType ? "Privada" : "Pública"}
          </div>
        </div>
      </div>
      <div
        className="adminpanel-box-option-value"
        style={{ textAlign: "center" }}
      >
        Ahora mismo la fase está marcada como:{" "}
        {!isdataForm.phaseType ? "Privada" : "Pública"}
      </div>
      {/* <div
        className="adminpanel-box-option-value"
        style={{ textAlign: "center" }}
      >
        La compra se ejecutará mediante BNB
      </div> */}
      <div className="adminpanel-box-button">
        <button
          className="modal--container--button-green"
          disabled={isLoadingAdmin}
        >
          {isLoadingAdmin ? "Cargando..." : "Crear Fase"}
        </button>
      </div>
      <div className="adminpanel-box-option-value">{onsuccessAdmin}</div>
      {error}
    </form>
  );
};

export default Ventas;
