import React from "react";

function ErrorIcon() {
  return (
    <svg
      width="36"
      height="33"
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.999 19L17.999 14"
        stroke="#E45F35"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7799 28.8263C34.172 29.4929 33.6913 30.3333 32.9179 30.3333H3.08142C2.30801 30.3333 1.82735 29.4929 2.21949 28.8263L17.1377 3.46529C17.5244 2.80798 18.475 2.80798 18.8616 3.46529L33.7799 28.8263Z"
        stroke="#E45F35"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.999 26C19.1036 26 19.999 25.1046 19.999 24C19.999 22.8954 19.1036 22 17.999 22C16.8945 22 15.999 22.8954 15.999 24C15.999 25.1046 16.8945 26 17.999 26Z"
        fill="#E45F35"
      />
    </svg>
  );
}

export default ErrorIcon;
