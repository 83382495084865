import { Contract, ethers } from "ethers";
import {
  contractBUSDAddress,
  busdABI,
  contractSaleAddress,
} from "../../../utils/constants";

// GET PHASES
export const getPhaseData = async (dispatch, saleContract, walletAddress) => {
  try {
    dispatch({
      type: "PHASE_LOADING",
    });
    //----------------------------------------------------------------------------------------------------------------------
    const getPhase = await saleContract?.getcurrentPhase();
    let tempObj = {};
    //----------------------------------------------------------------------------------------------------------------------
    tempObj.endAt = new Date(
      getPhase.endAt.toString() * 1000
    ).toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    tempObj.isPublic = getPhase.isPublic;
    tempObj.minimunEntry = ethers.utils.formatEther(
      getPhase.minimunEntry.toString()
    );
    tempObj.maxEntry = ethers.utils.formatEther(getPhase.maxEntry.toString());
    tempObj.over = getPhase.over;
    tempObj.price = ethers.utils.formatEther(getPhase.price.toString());
    tempObj.supply = ethers.utils.formatEther(getPhase.supply.toString());
    tempObj.timelock = getPhase.timelock.toString() / 3600 / 24;
    const getIDs = await saleContract.getIDs(walletAddress);
    let comprasRealizadas = [];
    if (getIDs.length !== 0) {
      const formatgetIDs = getIDs.map((o) => o.toString());
      await Promise.all(
        formatgetIDs.map(async (o) => {
          let tempObj = {};
          tempObj.id = o;
          tempObj.initAmout = await getshowMyinitAmount(saleContract, o);
          tempObj.remainingTokens = await getshowMyRemainAmount(
            saleContract,
            o
          );
          tempObj.date = await getWhenIsTheNextClaim(saleContract, o);
          tempObj.releaseDates = await getTimesToReleaseForID(saleContract, o);
          tempObj.percents = await getPercentsToReleaseForID(saleContract, o);
          comprasRealizadas.push(tempObj);
        })
      );
    }
    let latestPrice = await saleContract.getLatestPrice();
    const tokenPrice = getPhase.price / latestPrice;
    latestPrice = ethers.utils.formatEther(latestPrice);
    let currentPrice = ethers.utils.formatEther(getPhase.price);

    tempObj.tokenPrice = tokenPrice;
    tempObj.latestPrice = latestPrice;
    tempObj.currentPrice = currentPrice;
    //------------------------
    dispatch({
      type: "PHASE_GET_PHASES_SUCCESS",
      payload: { tempObj, comprasRealizadas },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
// GET PHASES
export const setPhasePurchase = async (dispatch, phaseData) => {
  try {
    dispatch({
      type: "PHASE_LOADING",
    });
    window.location.pathname = "/select-token";
    dispatch({
      type: "PHASE_SET_PHASE",
      payload: phaseData,
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
const getshowMyinitAmount = async (saleContract, o) => {
  let tokens = await saleContract.showMyinitAmount(o);
  return ethers.utils.formatEther(tokens);
};
const getshowMyRemainAmount = async (saleContract, o) => {
  let tokens = await saleContract.showMyRemainAmount(o);
  return ethers.utils.formatEther(tokens);
};

const getWhenIsTheNextClaim = async (saleContract, o) => {
  let datesToRelease = await saleContract.getWhenIsTheNextClaim(o);
  if (datesToRelease.toString() === "0") {
    return datesToRelease.toString();
  }
  let newDate = new Date(datesToRelease.toString() * 1000).toLocaleDateString(
    undefined,
    {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );
  return newDate;
};
const getTimesToReleaseForID = async (saleContract, o) => {
  let datesToRelease = await saleContract.getTimesToReleaseForID(o);
  let dates = datesToRelease.map((o) => {
    return new Date(o.toString() * 1000).toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  });

  return dates;
};
const getPercentsToReleaseForID = async (saleContract, o) => {
  let percentsForRelease = await saleContract.getPercentsToReleaseForID(o);
  let percentArr = percentsForRelease.map((o) => o.toString());
  return percentArr;
};
export const setBuyToken = async (
  dispatch,
  saleContract,
  isValue,
  walletAddress
) => {
  try {
    dispatch({
      type: "PHASE_LOADING",
    });
    // //------------------------
    const currentPhase = await saleContract.getcurrentPhase();
    let latestPrice = await saleContract.getLatestPrice();
    const tokenPrice = currentPhase.price / latestPrice;

    // // //------------------------
    let tokenValue = ethers.utils.parseUnits(isValue, "ether");
    let tokenValueWEI = ethers.utils.parseUnits(
      Math.ceil(tokenPrice * tokenValue).toString(),
      "wei"
    );
    // //------------------------
    const buyToken = await saleContract.buyToken(tokenValue, {
      from: walletAddress,
      value: tokenValueWEI,
    });
    const approval = await buyToken.wait();
    let transactionHash = approval.transactionHash;
    let message = `Se compro : ${isValue} Token 🎉`;
    dispatch({
      type: "PHASE_PURCHASE_TOKEN",
      payload: { transactionHash, message },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      if (error.reason === "execution reverted: Not enough supply") {
        dispatch({
          type: "PHASE_ERROR",
          payload: "Suministro insuficiente",
        });
      } else if (
        error.reason === "execution reverted: This phase is over, try next"
      ) {
        dispatch({
          type: "PHASE_ERROR",
          payload: "La Fase ha terminado, no se puede comprar por ahora",
        });
      } else {
        dispatch({
          type: "PHASE_ERROR",
          payload: error.reason,
        });
      }
    } else {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const setBuyTokenBusd = async (
  dispatch,
  saleContract,
  isValue,
  walletAddress,
  library,
  pricePhase
) => {
  try {
    dispatch({
      type: "PHASE_LOADING",
    });
    // //------------------------
    let tokenValue = ethers.utils.parseUnits(
      (isValue * pricePhase * 2).toString(),
      "ether"
    );
    const signer = library.getSigner();
    const busdContract = new Contract(contractBUSDAddress, busdABI, signer);
    const approve = await busdContract.approve(contractSaleAddress, tokenValue);
    const approval1 = await approve.wait();
    let isValueforstabletoken = ethers.utils.parseUnits(isValue, "ether");
    console.log(approval1);

    const buyToken = await saleContract.buyTokenWithStableToken(
      isValueforstabletoken,
      contractBUSDAddress
    );
    const approval2 = await buyToken.wait();
    console.log(approval2);

    let transactionHash = approval2.transactionHash;
    console.log(transactionHash);
    let message = `Se compro : ${isValue} Token 🎉`;
    dispatch({
      type: "PHASE_PURCHASE_TOKEN",
      payload: { transactionHash, message },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      if (error.reason === "execution reverted: Not enough supply") {
        dispatch({
          type: "PHASE_ERROR",
          payload: "Suministro insuficiente",
        });
      } else if (
        error.reason === "execution reverted: This phase is over, try next"
      ) {
        dispatch({
          type: "PHASE_ERROR",
          payload: "La Fase ha terminado, no se puede comprar por ahora",
        });
      } else {
        dispatch({
          type: "PHASE_ERROR",
          payload: error.reason,
        });
      }
    } else {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const releaseClaim = async (dispatch, id, saleContract) => {
  try {
    dispatch({
      type: "PHASE_LOADING",
    });
    const release = await saleContract.release(id);
    console.log(release);
    let message = "Claim fue realizado";
    let transactionHash = release.hash;

    dispatch({
      type: "PHASE_PURCHASE_TOKEN",
      payload: { transactionHash, message },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      if (error.reason === "execution reverted: Not enough supply") {
        dispatch({
          type: "PHASE_ERROR",
          payload: "Suministro insuficiente",
        });
      } else if (
        error.reason === "execution reverted: This phase is over, try next"
      ) {
        dispatch({
          type: "PHASE_ERROR",
          payload: "La Fase ha terminado, no se puede comprar por ahora",
        });
      } else {
        dispatch({
          type: "PHASE_ERROR",
          payload: error.reason,
        });
      }
    } else {
      dispatch({
        type: "PHASE_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
