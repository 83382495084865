const initialState = {
  isLoading: false,
  walletAddress: false,
  isWrongNetwork: false,
  error: false,
};

export const walletReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "WALLET_LOADING":
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case "WALLET_DATA":
      return {
        ...state,
        isLoading: false,
        walletAddress: payload.walletAddress,
        isWrongNetwork: payload.isWrongNetwork,
        error: false,
      };
    case "WALLET_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
