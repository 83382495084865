import { combineReducers } from "redux";
import { walletReducer } from "./Wallet";
import { adminPanelReducer } from "./AdminPanel";
import { phaseReducer } from "./Phase";

export const rootReducer = combineReducers({
  wallet: walletReducer,
  phase: phaseReducer,
  adminPanel: adminPanelReducer,
});
