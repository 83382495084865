import React, { useContext } from "react";
import { AlertIcon } from "../../assets/";
import { setWalletNetwork } from "../../store/Reducers/Wallet/actions";
import { WalletWeb3Context } from "../../context/WalletWeb3Context";
import { useDispatch } from "react-redux";

const WrongNetwork = ({ account }) => {
  const dispatch = useDispatch();
  const { library } = useContext(WalletWeb3Context);
  return (
    <>
      <div
        style={{ background: "rgba(159, 115, 230, 0.2)" }}
        className="modal--container--icon"
      >
        <AlertIcon />
      </div>
      <div className="modal--container--wallet">
        <div className="modal--container--wallet--title"> Conectado con :</div>
        <div className="modal--container--wallet--address">
          <div className="modal--container--wallet--connected" />
          <div>{account}</div>
        </div>
      </div>

      <button className="modal--container--divider" />
      <button
        className="modal--container--button-admin"
        onClick={() => setWalletNetwork(dispatch, library)}
      >
        Cambiar Network
      </button>
    </>
  );
};

export default WrongNetwork;
