import { Contract, ethers } from "ethers";
import {
  contractTokenAddress,
  tokenABI,
  contractSaleAddress,
  contractBUSDAddress,
} from "../../../utils/constants";
import { euroToDollar } from "../../../utils/";
export const setAdminData = async (dispatch, saleContract, walletAddress) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const getDispatcher = await saleContract.dispatcher();
    let isAdmin = getDispatcher === walletAddress;
    dispatch({
      type: "ADMIN_DATA",
      payload: {
        isAdmin,
      },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};
//DASHBOARD
export const setAdminDashbord = async (dispatch, saleContract) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const getcurrentPhase = await saleContract.getcurrentPhase();

    let tempObj = {};
    tempObj.endAt = new Date(
      getcurrentPhase.endAt.toString() * 1000
    ).toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    tempObj.supply = ethers.utils.formatEther(
      getcurrentPhase.supply.toString()
    );
    tempObj.price = ethers.utils.formatEther(getcurrentPhase.price.toString());
    tempObj.minimunEntry = ethers.utils.formatEther(
      getcurrentPhase.minimunEntry.toString()
    );
    tempObj.maximunEntry = ethers.utils.formatEther(
      getcurrentPhase.maxEntry.toString()
    );
    tempObj.isPublic = getcurrentPhase.isPublic;
    tempObj.over = getcurrentPhase.over;

    tempObj.timelock = parseInt(
      getcurrentPhase.timelock.toString() / 3600 / 24
    );

    let message = "Data actualizada";
    dispatch({
      type: "ADMIN_DASHBOARD",
      payload: { tempObj, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deletePhase = async (dispatch, saleContract) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const transaction = await saleContract.cancelPhase();
    const response = await transaction.wait();
    let message = "Phase Eliminada Deber Crear una en ventas";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, response },
    });
  } catch (error) {
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//VENTAS
export const setAdminPhase = async (
  dispatch,
  saleContract,
  dataForm,
  library
) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------
    // let newdecimal = new Decimal(euroToDollar(dataForm.price));
    // console.log(euroToDollar(dataForm.price));
    // console.log(euroToDollar(dataForm.price).toFixed(18));
    // const price = ethers.utils
    //   .parseUnits(euroToDollar(dataForm.price).toFixed(18), "ether")
    //   .toString();
    // console.log(price);

    const supply = ethers.utils
      .parseEther(dataForm.supply.toString())
      .toString();
    const minEntry = ethers.utils
      .parseEther(dataForm.minEntry.toString())
      .toString();
    const maxEntry = ethers.utils
      .parseEther(dataForm.maxEntry.toString())
      .toString();

    const price = ethers.utils
      .parseEther(euroToDollar(dataForm.price).toFixed(18))
      .toString();
    const endAt = new Date(dataForm.endAt).getTime() / 1000;
    const timelock = dataForm.timelock * 24 * 60 * 60;
    const timesToRelease = dataForm.timesToRelease
      .split(",")
      .sort()
      .map((x) => {
        return x * 24 * 60 * 60;
      });
    const percentsToRelease = dataForm.percentsToRelease
      .split(",")
      .map((percent) => parseFloat(percent));
    const phaseType = dataForm.phaseType;
    const signer = library.getSigner();
    const tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const transaction = await tokenContract.approve(
      contractSaleAddress,
      supply
    );
    const awaitTransaction = await transaction.wait();
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { awaitTransaction, message },
    });
    if (awaitTransaction.status === 1) {
      const transaction2 = await saleContract.createPhase(
        phaseType,
        maxEntry,
        minEntry,
        price,
        endAt,
        supply,
        timelock,
        timesToRelease,
        percentsToRelease
      );
      console.log(transaction2);
      const awaitTransaction2 = await transaction2.wait();

      message = "Fase fue creada Exitosamente";
      dispatch({
        type: "ADMIN_CREATE_PHASE",
        payload: { awaitTransaction2, message },
      });
    }
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//WHITELIST
export const setWhitelistWallets = async (
  dispatch,
  saleContract,
  isWallets
) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const addtoWhitelist = await saleContract.addToWhitelist(
      isWallets.split(",")
    );
    const transaction = await addtoWhitelist.wait();
    let message = "Wallets agregadas exitosamente.";

    dispatch({
      type: "ADMIN_ADD_WHITELIST",
      payload: { transaction, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const getWhitelistWallets = async (dispatch, saleContract) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const wallets = await saleContract.getWhitelist();
    let message = "Whitelist Actualizada";
    dispatch({
      type: "ADMIN_GET_WHITELIST",
      payload: { message, wallets },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deleteWhitelist = async (dispatch, saleContract) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const transaction = await saleContract.removeWhitelistedAddress();
    const awaittransaction = await transaction.wait();
    let message = "Wallets Eliminadas";
    dispatch({
      type: "ADMIN_DELETE_WHITELIST",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//WHITHDRAW
export const setWithdraw = async (dispatch, saleContract, isdataForm) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const amount = ethers.utils.parseEther(isdataForm.amount.toString());
    console.log(amount);
    const transaction = await saleContract.withdraw(isdataForm.address, amount);
    console.log(transaction);
    const awaittransaction = await transaction.wait();
    let message = `Se acaba de retirar:"${isdataForm.amount} BNB" a la wallet ${isdataForm.address} `;

    dispatch({
      type: "ADMIN_WITHDRAW",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const setWithdrawBUSD = async (dispatch, saleContract, isdataForm) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const amount = ethers.utils.parseEther(isdataForm.amount.toString());
    const transaction = await saleContract.withdrawStableToken(
      isdataForm.address,
      amount,
      contractBUSDAddress
    );
    const awaittransaction = await transaction.wait();
    let message = `Se acaba de retirar:"${isdataForm.amount} BUSD" a la wallet ${isdataForm.address} `;

    dispatch({
      type: "ADMIN_WITHDRAW",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//CONFIG
export const setDispatcher = async (dispatch, saleContract, address) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const updateDispatcher = await saleContract.changeDispatcher(address);
    let message = `Nuevo Dispatcher:${address} `;
    dispatch({
      type: "ADMIN_NEW_DISPATCHER",
      payload: { message, updateDispatcher },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const setDateEnd = async (dispatch, saleContract, dateEnd) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const transaction = await saleContract.changeEndDate(
      new Date(dateEnd).getTime() / 1000
    );
    const awaittransaction = await transaction.wait();
    let message = `Nuevo Fecha de Finalizacion : ${dateEnd} `;
    dispatch({
      type: "ADMIN_NEW_DATE_END",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};

////RESET MESSAGE
export const resetMessage = async (dispatch) => {
  dispatch({
    type: "ADMIN_RESET_MESSAGE",
  });
};
