/* eslint-disable */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdminDashbord,
  deletePhase,
} from "../../../store/Reducers/AdminPanel/actions";
import { localeString, dollarToEuro } from "../../../utils";

const Dashboard = ({ saleContract }) => {
  const dispatch = useDispatch();
  const { isLoadingAdmin, adminDashboard, onsuccessAdmin, error } = useSelector(
    ({ adminPanel }) => adminPanel
  );

  useEffect(() => {
    if (saleContract !== null) {
      setAdminDashbord(dispatch, saleContract);
    }
  }, [saleContract]);
  const onClickDeletePhase = () => {
    deletePhase(dispatch, saleContract);
  };
  return (
    <>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Estado de Fase :</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : adminDashboard?.over
            ? "Finalizada"
            : "En progreso"}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Fecha fin</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin ? "Cargando..." : adminDashboard?.endAt}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Tokens restantes</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : localeString(adminDashboard?.supply)}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Precio</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : localeString(dollarToEuro(adminDashboard?.price).toFixed(5)) +
              "€"}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Compra mínima</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : localeString(adminDashboard?.minimunEntry)}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Compra maxima</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : localeString(adminDashboard?.maximunEntry)}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Tipo de fase</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin
            ? "Cargando..."
            : adminDashboard?.isPublic
            ? "Publica"
            : "Privada"}
        </div>
      </div>
      <div className="adminpanel-box-option">
        <div className="adminpanel-box-option-name">Tiempo de bloqueo</div>
        <div className="adminpanel-box-option-value">
          {isLoadingAdmin ? "Cargando..." : adminDashboard?.timelock + " Dias"}
        </div>
      </div>
      <div className="adminpanel-box-button">
        <button
          disabled={isLoadingAdmin}
          className="modal--container--button-disconnect"
          onClick={() => onClickDeletePhase()}
        >
          {isLoadingAdmin ? "Cargando..." : "Eliminar Fase"}
        </button>
      </div>
      <div className="adminpanel-box-option-value">{onsuccessAdmin}</div>
      {error}
    </>
  );
};

export default Dashboard;
