/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WalletWeb3Provider } from "./context/WalletWeb3Context";
import { useSelector } from "react-redux";
import "./css/App.scss";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MainPage from "./Pages/MainPage";
import SecondPage from "./Pages/SecondPage";
import AdminPanel from "./Pages/AdminPanel";
import PrivateRoute from "./utils/PrivateRoute";
import Modal from "./components/Modal";
import WrongNetwork from "./components/WrongNetwork";
import { shortenAddressLong } from "./utils";
import Footer from "./components/Footer";

function App() {
  const [isModalWrongNetworkOpen, setisModalWrongNetworkOpen] = useState(false);
  const { isWrongNetwork, walletAddress } = useSelector(({ wallet }) => wallet);
  useEffect(() => {
    if (isWrongNetwork) {
      setisModalWrongNetworkOpen(() => true);
    } else {
      wrongnetworkModalClose();
    }
  }, [isWrongNetwork]);
  const wrongnetworkModalClose = () => {
    setisModalWrongNetworkOpen(() => false);
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WalletWeb3Provider>
        {!!walletAddress && (
          <Modal
            visible={isModalWrongNetworkOpen}
            onClose={() => wrongnetworkModalClose()}
          >
            <WrongNetwork account={shortenAddressLong(walletAddress)} />
          </Modal>
        )}
        <BrowserRouter>
          <Routes>
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminPanel />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<MainPage />} exact />
            <Route path="/compra" element={<SecondPage />} exact />
          </Routes>
          <Footer />
        </BrowserRouter>
      </WalletWeb3Provider>
    </Suspense>
  );
}

export default App;
