import React from "react";
import CorssIcon from "../assets/CrossIcon";

const MemeberDetail = ({ setOpen, userData }) => {
  return (
    <div className="memeber-detail flex">
      <div className="memeber-wrap flex">
        <div className="left flex flex-col">
          <img
            src={userData ? userData.img : "./images/team1.png"}
            className="img"
          />
          <div className="action flex items-center justify-center pt-5">
            <div className="btn button">Linkedin</div>
          </div>
        </div>
        <div className="right flex flex-col">
          <div className="hdr flex items-center justify-end">
            <div className="icon" onClick={(e) => setOpen(false)}>
              <CorssIcon />
            </div>
          </div>
          <div className="user-info flex flex-col">
            <div className="name">{userData ? userData.name : ""}</div>
            <div className="desi">{userData ? userData.designation : ""}</div>
          </div>
          <div className="desc-block flex flex-col">
            <div className="desc">
              Ingeniero Industrial por la Universidad de Sevilla y Executive PLD
              por ESADE y la Universidad de Georgetown. Su carrera profesional
              se ha desarrollado en España, Sudáfrica y Marruecos, con inicios
              en el sector del automóvil y posteriormente en el sector del
              metal, en los últimos 11 años ha liderado proyectos en el sector
              de las energías renovables, tanto en la termo solar como en la
              eólica. Acumula más de 800 MW a lo largo de 14. <br /> <br />
              Su esfuerzo ha contribuido a mejorar la vida de las personas,
              proveyendo de energía limpia a más de 280.560 hogares y reduciendo
              la emisión de 1.348.400 toneladas de CO2 cada año. Sus excelentes
              aptitudes humanas ayudan a establecer y consolidar relaciones
              sólidas con todas las partes involucradas en los proyectos. Es un
              líder comprometido, cualitativo y de confianza.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemeberDetail;
