/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  setWhitelistWallets,
  getWhitelistWallets,
  deleteWhitelist,
} from "../../../store/Reducers/AdminPanel/actions";
import { useSelector, useDispatch } from "react-redux";

const Whitelist = ({ saleContract }) => {
  const dispatch = useDispatch();
  const { isLoadingAdmin, onsuccessAdmin, error, walletsWhitelist } =
    useSelector(({ adminPanel }) => adminPanel);
  const [isWallets, setisWallets] = useState("");
  const [isWalletsListOpen, setisWalletsListOpen] = useState(false);
  const onchangeHandlerWallets = (e) => {
    const { value } = e.target;
    setisWallets(() => value);
  };
  useEffect(() => {
    if (saleContract !== null) {
      getWhitelistWallets(dispatch, saleContract);
    }
  }, [saleContract]);
  useEffect(() => {
    console.log(walletsWhitelist);
  }, [walletsWhitelist]);
  const onSubmitAddWallets = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setWhitelistWallets(dispatch, saleContract, isWallets);
  };
  const deleteWalletsHandler = () => {
    setisWalletsListOpen(false);
    deleteWhitelist(dispatch, saleContract);
  };
  return (
    <>
      <form className="adminpanel-form" onSubmit={onSubmitAddWallets}>
        <div className="adminpanel-input-container">
          <div className="adminpanel-input-title">
            Agregar wallets a la whitelist
          </div>
          <input
            onChange={onchangeHandlerWallets}
            type="text"
            value={isWallets}
            className="adminpanel-input-input"
            required
            minLength={42}
            placeholder="0x000000000000000"
          />
          <div className="adminpanel-input-info">
            Ingrese una o más wallets separadas por coma y sin espacios para
            agregar a la whitelist.
          </div>
        </div>
        <div className="adminpanel-box-button">
          <button
            disabled={isLoadingAdmin}
            className="modal--container--button-green"
          >
            {isLoadingAdmin ? "Cargando..." : "Agregar Wallets"}
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
      <div className="adminpanel-form">
        {walletsWhitelist.length > 1 && (
          <div className="adminpanel-input-title">
            Ver wallets dentro de la whitelist
          </div>
        )}
        {!!isWalletsListOpen && (
          <>
            {walletsWhitelist.map((address) => {
              if (address !== "0x0000000000000000000000000000000000000000") {
                return <li key={address}>{address}</li>;
              }
            })}
            {walletsWhitelist[0] ===
              "0x0000000000000000000000000000000000000000" && "Whitelist Vacio"}
          </>
        )}

        {walletsWhitelist.length > 0 && (
          <div className="adminpanel-box-button">
            <button
              disabled={isLoadingAdmin}
              className="modal--container--button-admin"
              onClick={() => setisWalletsListOpen((prev) => !prev)}
            >
              {isWalletsListOpen ? "Ocultar" : "Ver"} Wallets
            </button>
          </div>
        )}
      </div>
      <div className="adminpanel-form">
        <div className="adminpanel-input-title">Eliminar Wallets </div>
        <button
          onClick={() => deleteWalletsHandler()}
          disabled={isLoadingAdmin}
          className="modal--container--button-disconnect"
        >
          {isLoadingAdmin ? "Cargando..." : "Eliminar Wallets"}
        </button>
      </div>
      <div className="adminpanel-box-option-value">{onsuccessAdmin}</div>
      {error}
    </>
  );
};

export default Whitelist;
