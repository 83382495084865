import { MainNet, TestNet } from "../../../utils/constants";
export const setWalletData = async (dispatch, walletAddress, network) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });

    let isWrongNetwork = false;

    let chainId =
      process.env.REACT_APP_NETWORK_ENV === "mainnet"
        ? process.env.REACT_APP_MAINNET_CHAINID
        : process.env.REACT_APP_TESTNET_CHAINID;

    if (network.chainId !== parseInt(chainId)) {
      isWrongNetwork = true;
    }
    dispatch({
      type: "WALLET_DATA",
      payload: {
        walletAddress,
        isWrongNetwork,
      },
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error,
    });
  }
};
export const setWalletNetwork = async (dispatch, library) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        process.env.REACT_APP_NETWORK_ENV === "mainnet" ? MainNet : TestNet,
      ],
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error.message,
    });
  }
};
export const setWalletDisconnect = async (dispatch) => {
  dispatch({
    type: "DISCONNECT",
  });
};
export const setWalletError = async (dispatch, error) => {
  dispatch({
    type: "WALLET_ERROR",
    payload: error,
  });
};
