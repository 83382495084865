import React, { memo } from "react";
import TableHeaderOption from "./TableHeaderOption";
const TableHeader = ({ header }) => {
  return (
    <div className="table-header">
      {header.map((option, index) => {
        return <TableHeaderOption key={index} option={option} />;
      })}
    </div>
  );
};

export default memo(TableHeader);
