/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import Dashboard from "./Dashboard";
import Ventas from "./Ventas";
import Whitelist from "./Whitelist";
import Retiro from "./Retiro";
import Configuracion from "./Configuracion";
import Disclaimer from "../..//components/Disclaimer";

import { WalletWeb3Context } from "../../context/WalletWeb3Context";

import "./style.css";
import Header from "../../components/Header";

const AdminPanel = () => {
  const [istab, settab] = useState("Dashboard");
  const { saleContract, library } = useContext(WalletWeb3Context);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [istab]);

  return (
    <div className="adminpanel-container">
      <Disclaimer />
      <Header />
      <div className="tabs-outer-container">
        <div className="tabs-container">
          <div
            onClick={() => settab(() => "Dashboard")}
            className={istab === "Dashboard" ? "tabs-tabs-active" : "tabs-tabs"}
          >
            Dashboard
          </div>
          <div
            onClick={() => settab(() => "Ventas")}
            className={istab === "Ventas" ? "tabs-tabs-active" : "tabs-tabs"}
          >
            Ventas
          </div>
          <div
            onClick={() => settab(() => "Whitelist")}
            className={istab === "Whitelist" ? "tabs-tabs-active" : "tabs-tabs"}
          >
            Whitelist
          </div>
          <div
            onClick={() => settab(() => "Retiro")}
            className={istab === "Retiro" ? "tabs-tabs-active" : "tabs-tabs"}
          >
            Retiro
          </div>
          <div
            onClick={() => settab(() => "Configuracion")}
            className={
              istab === "Configuracion" ? "tabs-tabs-active" : "tabs-tabs"
            }
          >
            Configuracion
          </div>
        </div>
      </div>

      <div className="adminpanel-box">
        {istab === "Dashboard" && <Dashboard saleContract={saleContract} />}
        {istab === "Ventas" && (
          <Ventas saleContract={saleContract} library={library} />
        )}
        {istab === "Whitelist" && <Whitelist saleContract={saleContract} />}
        {istab === "Retiro" && <Retiro saleContract={saleContract} />}
        {istab === "Configuracion" && (
          <Configuracion saleContract={saleContract} />
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
